import { post } from "../apiClient";
import { nanoid } from "nanoid";
import { PhotoResults, SearchFilters } from "../../../Components/ImageSearch/ImageSearch.Interfaces";

async function fetchPhotosViaWlStore(
  query: string,
  page: number,
  pageSize: number,
  searchFilters: SearchFilters
): Promise<PhotoResults> {
  if (query === "") {
    return {
      photos: [],
      missingOrgs: [],
      photoCount: 0,
      format: "",
      // opacity: "",
      colour: "",
      isLatest: false,
      isBest: true,
      isAsc: true,
      sort: "",
      type: "logo",
      orgName: "",
      message: "",
      isImageAvailable: false,
      isAlias: false,
      status: "",
      // theme:"",
    };
  }

  const response = await post("/search", "wl-logostore-tester", {
    selectedItems: query,
    page,
    pageSize: pageSize,
    format: searchFilters.format,
    opacity: searchFilters.opacity,
    colour: searchFilters.colour,
    isLatest: searchFilters.isLatest,
    isBest: searchFilters.isBest,
    isAsc: searchFilters.isAsc,
    sortColumn: searchFilters.sort,
    type: searchFilters.type,
    isAlias: searchFilters.isAlias,
    status: searchFilters.status,
    theme: searchFilters.theme,
  });

  if (!response.success) {
    const err = await JSON.parse(JSON.stringify(response));
    return err;
    // throw err.message ?? "An error occurred";
  }

  const json = await JSON.parse(JSON.stringify(response));

  // console.log("JSON DATA", json);

  if (json.data == null || json.data.tiles == null) {
    return {
      missingOrgs: [],
      photos: [],
      photoCount: 0,
      format: "",
      // opacity: "",
      colour: "",
      isLatest: false,
      isBest: true,
      isAsc: true,
      sort: "",
      type: "",
      orgName: "",
      message: "",
      isImageAvailable: false,
      isAlias: false,
      status: "",
      theme: "",
    };
  }

  // const photos = json.data.logos.map((logo: any) => ({
  //   id: nanoid(),
  //   previewUrl: logo.previewUrl,
  //   url: logo.imageUrl,
  //   width: logo.width,
  //   height: logo.height,
  //   searchSource: "wlStore",
  //   opacity: logo.opacity,
  //   colour: logo.colour,
  //   isLatest: logo.isLatest,
  //   isBest: logo.isBest,
  //   isAsc: logo.isAsc,
  //   type: logo.type,
  //   orgName: logo.orgName,
  //   format: logo.format,
  //   isImageAvailable: logo.isImageAvailable,
  //   isAlias: logo.isAlias,
  //   status:logo.status,
  // }));

  const photos = json.data.tiles.map((tile: any) =>
    tile.logos.map((logo: any) => ({
      id: nanoid(),
      brandId: logo.brandId,
      isFavorite: logo.isFavorite,
      imageId: logo.imageId,
      previewUrl: logo.previewUrl,
      url: logo.imageUrl,
      width: logo.width,
      height: logo.height,
      searchSource: "wlStore",
      opacity: logo.opacity,
      colour: logo.colour,
      isLatest: logo.isLatest,
      isBest: logo.isBest,
      isAsc: logo.isAsc,
      type: logo.type,
      orgName: tile.orgName, // Changed to tile.orgName
      format: logo.format,
      isImageAvailable: logo.isImageAvailable,
      isAlias: logo.isAlias,
      status: logo.status,
      theme: logo.theme,
      userFavoriteId: logo.userFavoriteId,
    }))
  );
  return {
    photos,
    missingOrgs: json.data.missingOrgs,
    photoCount: json.data.totalLogoCount,
    opacity: json.data.opacity,
    format: json.data.format,
    colour: json.data.colour,
    isLatest: json.data.isLatest,
    isBest: json.data.isBest,
    sort: json.data.sort,
    type: json.data.type,
    isAsc: json.data.isAsc,
    orgName: json.data.orgName,
    message: json.message,
    isImageAvailable: json.data.isImageAvailable,
    isAlias: json.data.isAlias,
    status: json.data.status,
    theme: json.data.status,
  };
}

export default fetchPhotosViaWlStore;
