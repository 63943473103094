import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { apiError } from "../redux.interfaces";

const initialState: apiError = {
  apiError: {
    success: true,
    message: "",
    status: 0,
    detailedMessages: [],
    data: null,
  },
  brandLimitReached: false,
};

export const ApiErrorSlice = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    UpdateApiError: (state, action: PayloadAction<{ apiError: unknown }>) => {
      state.apiError = action.payload.apiError;
    },
    UpdateBrandLimitReached: (state, action: PayloadAction<{ brandLimitReached: boolean }>) => {
      state.brandLimitReached = action.payload.brandLimitReached;
    },
  },
});

export default ApiErrorSlice.reducer;
export const { UpdateApiError } = ApiErrorSlice.actions;
export const { UpdateBrandLimitReached } = ApiErrorSlice.actions;
