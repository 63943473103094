import { Amplify, API, Auth } from "aws-amplify";
import { getSessionData } from "../Auth/auth";
import { constructAbsoluteUrl, getTenantId } from "../../../url-helpers";
import wlConfig from "../../../wlConfig";
// import { useDispatch } from "react-redux";
import { store } from "../../Redux/store/store"; // Import the store
import { UpdateApiError, UpdateBrandLimitReached } from "../../Redux/features/apiError";

fetchAndConfigureTenant();

async function fetchAndConfigureTenant() {
  try {
    const tenantId = await getTenantId();
    if (tenantId) {
      const response = await fetch(`${wlConfig.configurationEndpoint}/tenant/${tenantId}`);
      const config = await response.json();
      // console.log(config, "tenant config from api")
      const authObj = {
        Auth: {
          identityPoolId: config.data.identityPoolId,
          region: wlConfig.region,
          userPoolId: wlConfig.userPoolId,
          userPoolWebClientId: config.data.clientId,
        },
        API: {
          endpoints: [
            {
              name: "wl-logostore-tester",
              // endpoint: "https://logo-api.sandigital.uk",
              endpoint: wlConfig.apiEndpoint,
            },
          ],
        },
        oauth: {
          domain: wlConfig.cognitoDomain,
          scope: wlConfig.scope,
          redirectSignIn: constructAbsoluteUrl("/login.html"),
          redirectSignOut: constructAbsoluteUrl("/taskpane.html"),
          responseType: wlConfig.responseType,
          clientId: config.data.clientId,
        },
      };
      Amplify.configure(authObj);
      return config;
    } else {
      throw new Error("Tenant ID is not available");
    }
  } catch (error) {
    console.error("Failed to fetch Amplify configuration:", error);
    throw error;
  }
}

export const myInitHeader = (currentSession: any) => ({
  headers: {
    Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
  },
});

// export async function post(path: any, name: any, data: any, customresHeader = {}, customreqHeader = {}) {
//   let apiName = name;
//   const myNewInit = myInitHeader(await getSessionData());
//   let myInit = {
//     body: data,
//     headers: { ...myNewInit.headers, ...customreqHeader },
//     ...customresHeader,
//   };

//   return API.post(apiName, path, myInit)
//     .then((response) => {
//       // console.log(response);
//       return response;
//     })
//     .catch((error) => {
//       console.log(error);
//       throw error;
//     });
// }

export async function post(path: any, name: any, data: any, customresHeader = {}, customreqHeader = {}) {
  // console.log(`Calling POST ${name} with path ${path}`);
  // console.log(`Calling POST ${name} with path ${path} and data ${JSON.stringify(data)}`);
  const apiName = name;
  const myNewInit = myInitHeader(await getSessionData());
  const myInit = {
    body: data,
    headers: { ...myNewInit.headers, ...customreqHeader },
    ...customresHeader,
  };

  // console.log(`Initializing POST ${name}`);
  // console.log(`Initializing POST ${name} with headers ${JSON.stringify(myInit.headers)}`);

  return API.post(apiName, path, myInit)
    .then((response) => {
      // console.log(`API POST ${name} returned response ${JSON.stringify(response)}`);
      // console.log("response", response);
      store.dispatch(UpdateBrandLimitReached({ brandLimitReached: false }));
      return response;
    })
    .catch((error) => {
      // console.log("iman");
      // console.error(`API POST ${name} failed with error ${error}`);
      const errorData = getValidErrors(error, "/search");
      // console.log("errorData", errorData);
      // console.log(errorData.detailedMessages); // access detailedMessages field
      // throw error;
      // set veriable in store which can be accessed by components..
      // or React Context API can be used to set the e
      // rror message and display it in the component
      // UpdateApiError({ apiError: errorData })

      const searchString1 = "You have exhausted";
      const searchString2 = "You are exceeding";
      if (errorData.detailedMessages.includes(searchString1) || errorData.detailedMessages.includes(searchString2)) {
        store.dispatch(UpdateBrandLimitReached({ brandLimitReached: true }));
      }

      store.dispatch(UpdateApiError({ apiError: errorData }));

      // if (errorData.detailedMessages && errorData.detailedMessages.length > 0) {
      //   const found = errorData.detailedMessages.find((element: any) => element.includes(searchString));
      //   if (found) {
      //     store.dispatch(UpdateBrandLimitReached({ brandLimitReached: true }));
      //   }
      // }

      return errorData;
    });
}

export async function newPost(path: any, name: any, data: any, customresHeader = {}, customreqHeader = {}) {
  const apiName = name;
  const myNewInit = myInitHeader(await getSessionData());
  const myInit = {
    body: data,
    headers: { ...myNewInit.headers, ...customreqHeader },
    ...customresHeader,
  };

  return API.post(apiName, path, myInit)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export async function put(path: any, name: any, data: any, customresHeader = {}, customreqHeader = {}) {
  const apiName = name;
  const myNewInit = myInitHeader(await getSessionData());
  const myInit = {
    body: data,
    headers: { ...myNewInit.headers, ...customreqHeader },
    ...customresHeader,
  };

  return API.put(apiName, path, myInit)
    .then((response) => {
      // console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export async function unauthenticatedPost(path: any, name: any, data: any) {
  const apiName = name;

  const myInit = {
    body: data,
    headers: {},
  };

  return API.post(apiName, path, myInit)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export async function get(path: string, name: string) {
  const apiName = name;
  const myNewInit = myInitHeader(await getSessionData());

  return API.get(apiName, path, myNewInit)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw getValidErrors(e, path);
    });
}

export const del = async (path: string, name: string) => {
  let apiName = name;
  const myNewInit = myInitHeader(await getSessionData());
  return API.del(apiName, path, myNewInit)
    .then((response) => {
      return response;
    })
    .catch((e: any) => {
      throw new Error(e);
      // throw getValidErrors(e, path);
    });
};

const getValidErrors = (error: any, apiUrl: string) => {
  const errorDetails = error.response;
  if (errorDetails) {
    const { message, error: statusText, success, detailedMessages, data } = errorDetails.data || {};
    const { status } = errorDetails;

    if (status === 401 || status === 403) {
      window.location.href = "/print/unauthorised?status=UnauthorisedStatusFromAPI";
    }
    if (status === 404) {
      window.location.href = "/print/PageNotFound?status=PageNotFoundfromAPI";
    }
    if (status >= 500) {
      window.location.href = "/print/error?status=InternalServerErrorFromAPI";
    }
    // if(status === 400){
    // 	window.location.href = '/print/error?status=BadRequest'
    // }

    return { success, statusText, status, message, detailedMessages, data };
  }

  return {
    error: true,
    success: false,
    statusText: `${error.response} ${apiUrl}`,
    status: 500,
    message: "server is temporarily down.",
  };
};
