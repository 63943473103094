import React from "react";
import errorIcon from "../../assets/error.svg";
// import deleteIcon from "../../assets/deleteIcon.svg";
import exitIcon from "../../assets/exit.svg";
import warningIcon from "../../assets/warning.svg";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  type?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, onClose, message, type }) => {
  if (!isOpen) return null;

  const getResult = (type: any) => {
    switch (type) {
      case "warning":
        return {
          typeClass: "warning",
          typeIcon: warningIcon,
          typeTitle: "Exceeding Daily Limit",
        };
      case "alert":
        return {
          typeClass: "alert",
          typeIcon: errorIcon,
          typeTitle: "Daily Limit Reached",
        };
      default:
        return {
          typeClass: "",
          typeIcon: null,
        };
    }
  };

  return (
    <div
      // className="modal-overlay"
      className={`modal-overlay ${getResult(type).typeClass}`}
    >
      <div className="modal-content">
        <header className="modal-header">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }} className="modal-header-inner">
            <img src={getResult(type).typeIcon} alt="warning" onClick={onClose} />
            <span className="modal-title">{getResult(type).typeTitle}</span>
          </div>
          <div onClick={onClose} className="modal-close-icon">
            <img src={exitIcon} alt="delete" />
          </div>
        </header>

        <div className="sp-modal">
          <p>{message}</p>
        </div>

        <div
          style={{ display: "flex", alignItems: "right", gap: "5px", justifyContent: "flex-end", marginRight: "10px" }}
          onClick={onClose}
        >
          <div className="modal-close" onClick={onClose}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
