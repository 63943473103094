import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import _, { get, set, size, debounce } from "lodash";
import {
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Checkbox,
  TooltipHost,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
  IDialogStyles,
  IDialogContentStyles,
  IButtonStyles,
} from "@fluentui/react";
import { PhotoGrid } from "../../Components/ImageSearch/PhotoGrid/PhotoGrid";
import { SearchAutoComplete } from "../../Components/SearchAutoComplete/SearchAutoComplete";
import { getClassNames } from "./Styles/SearchPage";
import { returnOrgLogos } from "../../Components/ImageSearch/Search";
import { MissingOrgsPopup } from "../../Components/ImageSearch/MissingOrgs/MissingOrgsPopup";
import { RequestMissingLogos } from "../../Components/ImageSearch/RequestOrgs/RequestMissingLogos";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { reset } from "../../Redux/features/filterSlice";
import { createTicketForMissingLogos } from "../../Services/API/missingLogos/createTicketForMissingLogos";
import { resetSelectedOrgs, updateSelectedOrgs } from "../../Redux/features/selectedOrgsSlice";
import { GridOptionsPopup } from "../../Components/GridOptions/GridOptionsPopUp/GridOptionsPopup";
import { hasFeatureGridInsert } from "../../Util/officeAppsAPI";
import FilterPanel from "../../Components/FilterPanel/FilterPanel";
import { findShape } from "../../Components/ImageInsertion/ImageInsertionHelpers";
import chev from "../../assets/chev.svg";
// import warningIcon from "../../assets/warning.svg";
import errorIcon from "../../assets/error.svg";
// import { ReactComponent as WarningIcon } from "../../assets/error.svg"; // Import the SVG icon
import deleteIcon from "../../assets/deleteIcon.svg";
import exitIcon from "../../assets/exit.svg";
import filterIcon from "../../assets/filter.svg";
import CustomModal from "../../Components/CustomModal/CustomModal";

import {
  findMinimumImageWidth,
  getImageAsBase64String,
  insertImagesFromBase64Strings,
} from "../../Components/ImageInsertion/Images";
import "./Styles/SearchPage.css";
import { Photo } from "../../Components/ImageSearch/ImageSearch.Interfaces";
import { GridDimensions } from "../../Components/ImageInsertion/ImageInsertion.Interfaces";
import RetainShapeValidationErrorPopUp from "../../Components/ImageInsertion/RetainShapeValidationErrorPopUp";
import { UpdateCompressImagesInputStatus } from "../../Redux/features/insertedLogoFormat";
import { UpdateMissingOrgsList } from "../../Redux/features/missingOrgs";
import { UpdateLogoPadding } from "../../Redux/features/logoPadding";
import SaveSearch from "../../Components/SaveSearch/SaveSearch";
import tickSaved from "../../assets/tickSaved.svg";
import crossSaved from "../../assets/crossSaved.svg";
import { sendSavedSearch } from "../../Services/API/savedSearch/sendSavedSearch";
import { getSavedSearches } from "../../Services/API/savedSearch/getSavedSearches";
import { deleteSavedSearch } from "../../Services/API/savedSearch/deleteSavedSearch";
import { getFavs } from "../../Services/API/Favorites/getFavs";
import { send } from "process";

type SearchSource = "wlStore" | "searchEngine";

const { bottomPanelClass, inputWrapper } = getClassNames();

// Spinner during search
function Busy() {
  return (
    <Spinner
      label="Fetching images..."
      ariaLive="assertive"
      labelPosition="top"
      size={SpinnerSize.large}
      className="mt-4"
    />
  );
}

// No logo's found
function NoPhotos({ hasQuery }: { hasQuery: boolean }) {
  return <>{hasQuery && <p>No logos found</p>}</>;
}

export function SearchPage() {
  // Redux
  const dispatch = useAppDispatch();
  const selectedOrgs = useAppSelector((state) => state.Orgs.selectedOrgs);
  const searchFilters = useAppSelector((state) => state.filters.searchFilters);
  const filterCount = useAppSelector((state) => state.filters.searchFilters.count);
  const altCount = useAppSelector((state) => state.filters.searchFilters.altCount);
  const isBest = useAppSelector((state) => state.filters.searchFilters.isBest);
  const logoPadding = useAppSelector((state: any) => state.logoPaddingRedux.logoPadding);
  const apiError = useAppSelector((state: any) => state.apiErrorRedux.apiError);
  const brandLimitReached = useAppSelector((state: any) => state.apiErrorRedux.brandLimitReached);
  const [hideDialog, setHideDialog] = useState(false);

  // Local state
  const [loading, setLoading] = useState(false);
  const [savedLoading, setSavedLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [invalidSearchMessage, setInvalidSearchMessage] = useState<string>("");
  const [savedErrorMessage, setSavedErrorMessage] = useState<string>("");
  const [showLimitWarning, setShowLimitWarning] = useState<boolean>(false);
  const [isSaveSearchOpen, setIsSaveSearchOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>("logos");
  const [isAddNewSearchOpen, setIsAddNewSearchOpen] = useState<boolean>(false);
  const [isViewSavedHistoryOpen, setIsViewSavedHistoryOpen] = useState<boolean>(false);
  const [isViewAllSearchesOpen, setIsViewAllSearchesOpen] = useState<boolean>(false);
  const [requestMissingLogos, setRequestMissingLogos] = useState(false);
  const [isRequestMissingLogosSuccessful, setIsRequestMissingLogosSuccessful] = useState<any>(undefined);
  const [showMissingOrgsPopup, setMissingOrgsPopup] = useState<boolean>(false);

  const [photos, setPhotos] = useState<any>([]);
  const [favPhotos, setFavPhotos] = useState<any>([]);
  const [selectedPhotos, setSelectedPhotos] = useState<any[]>([]);
  const [isAllSelected, setAllSelected] = useState<boolean | undefined>(false);

  const [page, setPage] = useState(1);
  const [filterUpdateCount, setFilterUpdateCount] = useState(0);
  const [isNextPageLoading, setNextPageLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const [errorType, setErrorType] = useState("");

  const [finalTags, setFinalTags] = useState<any>([]);
  const [searchQueryPhoto, setSearchQueryPhoto] = useState("");
  const [searchSource, setSearchSource] = useState<SearchSource>("wlStore");
  const [searchTags, setSearchTags] = useState([]);
  const [currentlySelected, setCurrentlySelected] = useState<any>([]); // preserve selections in auto-complete during re-renders

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showFavourites, setShowFavourites] = useState<boolean>(false);
  const [clearTags, setClearTags] = useState<boolean>(false);
  const [searchSavedSuccessful, setSearchSavedSuccessful] = useState<boolean>(false);
  const [searchSavedFail, setSearchSavedFail] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("logos");
  const [showGridOptions, setShowGridOptions] = useState(false);
  const [isDistributeHorizontallySelected, setDistributeHorizontallySelected] = useState(false);
  const [alignment, setAlignment] = useState<string>("center");
  const [isShapeSelected, setIsShapeSelected] = useState<boolean>(false);
  const [isRetainShapeSelected, setIsRetainShapeSelected] = useState<boolean>(false);
  const [retainShapeValidationErrorMessage, setRetainShapeValidationErrorMessage] = useState<boolean>(false);
  const [retainShapeValidationErrorMessagePopUp, setRetainShapeValidationErrorMessagePopUp] = useState<boolean>(false);
  const isCompressImagesSelected = useAppSelector((state) => state.insertedLogoFormat.logoFormat);
  const missingOrgList = useAppSelector((state) => state.missingOrgsFormatRedux.missingOrgs);
  const [currentFavImageOnGrid, setCurrentFavImageOnGrid] = useState<Photo[]>([]);

  const [savedSearchName, setSavedSearchName] = useState<string>("");
  const [savedList, setSavedList] = useState<any>({ savedSearches: [], totalCount: 0 });

  const [showBrandLimitWarning, setShowBrandLimitWarning] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const [currentImageOnTheGrid, setCurrentImageOnTheGrid] = useState<Photo[]>([]);

  //currentImageOnTheGrid will hold array of images displayed on the photo grid at the moment
  let currentImageArray: any[];
  useEffect(() => {
    currentImageArray = photos.map((item: any) => {
      return item[0];
    });
    setCurrentImageOnTheGrid(currentImageArray);
  }, [photos, showFavourites, showFilters]);

  useEffect(() => {
    if (!showFavourites) {
      if (photos.length > 0 && selectedPhotos.length === photos.length) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
    } else {
      if (selectedPhotos.length === favPhotos.length) {
        if (favPhotos.length > 0) {
          setAllSelected(true);
        } else {
          setAllSelected(false);
        }
      } else {
        setAllSelected(false);
      }
    }
  }, [selectedPhotos, photos, favPhotos]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchSavedSuccessful) {
        setSearchSavedSuccessful(false);
      }
      if (searchSavedFail) {
        setSearchSavedFail(false);
      }
      if (error) {
        setError(undefined);
      }
    }, 5000);

    return () => {
      // console.log("Clearing timeout");
      clearTimeout(timer);
    };
  }, [searchSavedSuccessful, searchSavedFail, error]);

  useEffect(() => {
    // console.log("useEffect triggered searchQueryPhoto, searchSource");
    setLoading(true);
    setPage(1);
    setSelectedPhotos([]);
    setNextPageLoading(false);

    returnOrgLogos(searchQueryPhoto, page, searchSource, searchFilters)
      .then((data) => {
        if (data.missingOrgs.length > 0) {
          // console.log("Missing organizations found:", data.missingOrgs);
          setMissingOrgsPopup(true);
          dispatch(UpdateMissingOrgsList({ missingOrgs: data.missingOrgs }));
        }
        setPhotos(data.photos);
        setIsLastPage(data.isLastPage);
        setLoading(false);
        setAllSelected(false);
        set;
      })
      .catch((error) => {
        console.log("Error in returnOrgLogos:", error);
        setLoading(false);
      });
    setShowFavourites(false);
    setCurrentTab("logos");
  }, [searchQueryPhoto, searchSource, filterUpdateCount]);

  useEffect(() => {
    if (brandLimitReached) {
      setShowBrandLimitWarning(true);
    }
  }, [brandLimitReached]);

  const getLogoList = async () => {
    // console.log("getLogoList triggered");
    setLoading(true);
    returnOrgLogos(searchQueryPhoto, page, searchSource, searchFilters).then((data) => {
      if (data.missingOrgs.length > 0) {
        setMissingOrgsPopup(true);
        dispatch(UpdateMissingOrgsList({ missingOrgs: data.missingOrgs }));
      }
      setPhotos(data.photos);
      setIsLastPage(data.isLastPage);
      setLoading(false);
      setAllSelected(false);
    });
  };
  const getUserFav = async () => {
    // console.log("getUserFav triggered");
    setLoading(true);
    const res = await getFavs();
    // console.log("res.data", res.data);

    const renamedArray = res.data.map((obj: any) => [
      {
        ...obj,
        id: obj.imageId,
        // imageUrl: obj.previewUrl,
        isFavorite: obj.isFavourite,
        url: obj.previewUrl,
        // orgName: "Gem",
        // isImageAvailable: true,
        isFavourite: undefined,
      },
    ]);

    setFavPhotos(renamedArray);
    setLoading(false);
  };

  useEffect(() => {
    setCurrentFavImageOnGrid(favPhotos);
  }, [favPhotos]);

  const performSearch = async (query: any) => {
    // console.log("Starting search with query:", query);
    setError(undefined);
    setInvalidSearchMessage("");
    setSelectedPhotos([]);
    setFilterUpdateCount((prev) => prev + 1);
    setSearchQueryPhoto(query);
  };

  const clearSearch = useCallback(() => {
    // console.log("clearSearch triggered");
    setSearchTags([]);
    setClearTags(true);
    dispatch(UpdateMissingOrgsList({ missingOrgs: [] }));
    setPhotos([]);
    setShowFilters(false);
    setInvalidSearchMessage("");
    dispatch(resetSelectedOrgs());
    // dispatch(reset());
    setSearchQueryPhoto("");
    setError(undefined);
  }, []);

  const closeDialog = () => {
    // console.log("closeDialog triggered");
    setHideDialog(false);
  };

  const handleAppliedFilters = () => {
    // const filteredSearchTags = selectedOrgs.filter((item: any) => item.trim() !== "");
    setShowFilters(false);
    dispatch(UpdateCompressImagesInputStatus({ logoFormat: false }));
    setIsRetainShapeSelected(false);
    performSearch(selectedOrgs);
  };

  async function handleSelectPhoto(photo: Photo) {
    const newSelectedOrder = selectedPhotos.slice();
    if (newSelectedOrder.includes(photo)) {
      newSelectedOrder.splice(newSelectedOrder.indexOf(photo), 1);
    } else {
      newSelectedOrder.push(photo);
    }
    setSelectedPhotos(newSelectedOrder);
  }

  const handleSearch = useCallback((searchTags: any) => {
    // console.log("handleSearch triggered with searchTags:", searchTags);

    if (searchTags.length > 0) {
      // dispatch(UpdateMissingOrgsList({ missingOrgs: [] }));
      performSearch(searchTags);
    }

    dispatch(UpdateCompressImagesInputStatus({ logoFormat: false }));
    setIsRetainShapeSelected(false);
  }, []);

  async function onChangeSelectAll(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
    if (isChecked) {
      if (showFavourites) {
        const selectedPhotos = currentFavImageOnGrid.flat();
        setSelectedPhotos(selectedPhotos);
      } else {
        const selectedPhotos = currentImageOnTheGrid.flat();
        setSelectedPhotos(selectedPhotos);
      }
      setAllSelected(true);
    } else {
      setSelectedPhotos([]);
      setAllSelected(false);
    }
  }

  async function handleMissingLogo(missingOrgs: any) {
    if (missingOrgs.length > 0) {
      await createTicketForMissingLogos(missingOrgs, searchFilters, setIsRequestMissingLogosSuccessful);
    }
  }

  const appendNextPage = () => {
    if (!isNextPageLoading && !isLastPage) {
      setNextPageLoading(true);

      returnOrgLogos(searchQueryPhoto, page + 1, searchSource, searchFilters).then((data) => {
        let newPhotos = [...photos];
        newPhotos = newPhotos.concat(data.photos);
        setAllSelected(false);
        setPhotos(newPhotos);
        setPage(page + 1);
        setIsLastPage(data.isLastPage);
        setNextPageLoading(false);
      });
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const scrollBottomTrigger = 50; // How far from the bottom before the next page load is taken
    const target = e.target as HTMLElement;
    const bottom = target.scrollHeight - target.scrollTop < target.clientHeight + scrollBottomTrigger;
    if (bottom) {
      appendNextPage();
    }
  };

  const resetFilters = () => {
    // const filteredSearchTags = selectedOrgs.filter((item: any) => item.trim() !== "");
    // console.log("resetFilters triggered");
    if (filterCount.length > 0 || altCount.length > 0) {
      performSearch(searchTags);
    }
    setShowFilters(false);

    dispatch(reset());
    setIsRetainShapeSelected(false);
    dispatch(UpdateCompressImagesInputStatus({ logoFormat: false }));

    // performSearch(searchTags);
  };

  // Image Insertion
  async function addImagesToDocument(photos: Photo[], insertGridDimensions: GridDimensions) {
    try {
      setError(undefined);

      const minImageWidth = await findMinimumImageWidth(insertGridDimensions);
      const base64Images = await Promise.all(
        photos.map((photo) =>
          getImageAsBase64String(
            photo.url,
            minImageWidth,
            photo.orgName,
            photo.isImageAvailable,
            isCompressImagesSelected
          )
        )
      );
      await insertImagesFromBase64Strings(
        base64Images,
        insertGridDimensions,
        alignment,
        isRetainShapeSelected,
        isDistributeHorizontallySelected,
        logoPadding
      );
      setAlignment("center");
      setDistributeHorizontallySelected(false);
      dispatch(UpdateLogoPadding({ logoPadding: 0 }));
    } catch (err: any) {
      console.error(err);
      setError(`Unable to use image`);
      throw err;
    }
  }

  const fetchSavedList = async (pageNum: number, pageSize: number) => {
    setSavedLoading(true);
    try {
      const res = await getSavedSearches(pageNum, pageSize);
      setSavedList({ savedSearches: res.data.saveSearches, totalCount: res.data.totalCount });
      setSavedLoading(false);
    } catch (error) {
      const errorData = error as any;
      console.log(`Error fetching saved search list`, errorData);
      console.log(`Detailed error messages`, errorData.detailedMessages);
    }
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsAddNewSearchOpen(false);
        setIsViewSavedHistoryOpen(false);
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [modalRef]);

  useEffect(() => {
    // console.log("apiError", apiError);
    // console.log("apiError.detailedMessage[0] ", apiError.detailedMessage[0]);

    // const searchString = "You have exhausted";
    const searchString1 = "You have exhausted";
    const searchString2 = "You are exceeding";
    const searchString3 = "Organization name is mandatory";

    // const getResult = (type: any) => {
    //   switch (type) {
    //     case "warning":
    //       return "warning";
    //     case "alert":
    //       return "alert";
    //     default:
    //       return "";
    //   }
    // };

    if (apiError && apiError.detailedMessages && apiError.detailedMessages.length > 0) {
      clearSearch();
      const found1 = apiError.detailedMessages.find((element: any) => element.includes(searchString1));
      const found2 = apiError.detailedMessages.find((element: any) => element.includes(searchString2));
      const found3 = apiError.detailedMessages.find((element: any) => element.includes(searchString3));
      if (found2) {
        setErrorType("warning");
        setHideDialog(true);
      } else if (found1) {
        setErrorType("alert");
        setHideDialog(true);
      }
    } else {
      // setHideDialog(true);
    }

    // if (apiError && apiError.success === false) {
    //   setHideDialog(true);
    // }
  }, [apiError]);

  useEffect(() => {
    if (finalTags.length > 0) {
      dispatch(resetSelectedOrgs());
      setClearTags(false);

      const orgNames = finalTags.map((org: any) => ({
        Key: org.searchFieldName || "",
        Value: org.name.toString(),
      }));

      setSearchTags(orgNames);
      dispatch(updateSelectedOrgs({ NewOrgNames: orgNames }));
      setShowLimitWarning(searchSource === "searchEngine" && orgNames.length === 15 ? true : false);

      handleSearch(orgNames);
    } else if (finalTags.length === 0) {
      clearSearch();
    }
  }, [finalTags]);

  useEffect(() => {
    dispatch(reset());
    // getFavs();
  }, []);

  async function showInsertGrid() {
    let shape;
    let isShapeLine;
    // check if powerpoint
    if (hasFeatureGridInsert()) {
      shape = await findShape();

      if (shape !== null) {
        isShapeLine = shape.name.includes("Straight" && "Connector");
      }
      if (shape === null && isRetainShapeSelected === true) {
        setRetainShapeValidationErrorMessagePopUp(true);
      } else {
        setRetainShapeValidationErrorMessagePopUp(false);

        if (selectedPhotos.length > 1) {
          setShowGridOptions(true);
        } else {
          addImagesToDocument(selectedPhotos, { rows: 1, columns: 1 });
        }
      }
    } else {
      addImagesToDocument(selectedPhotos, { rows: 1, columns: 1 });
    }
  }

  const handleSelectedSavedSearch = (selectedList: any) => {
    // console.log("handleSelectedSavedSearch triggered with selectedList:", selectedList);
    const additionalOrgs = selectedList.map((org: any, index: number) => ({
      key: org + index,
      name: org.toString(),
    }));

    const allOrgs = [...currentlySelected, ...additionalOrgs];

    const filteredOrgs = allOrgs.filter((obj, index, arr) => {
      return arr.map((item) => item.name.toLowerCase()).indexOf(obj.name.toLowerCase()) === index;
    });

    updateSelectedOrgs({ NewOrgNames: filteredOrgs });
    setCurrentlySelected(filteredOrgs);
  };

  const deleteSearch = async (id: any) => {
    // console.log("deleteSearch triggered with id:", id);
    const res = await deleteSavedSearch(id);
    fetchSavedList(1, 100);
  };

  const returnSavedSearch = (searchString: any[]) => {
    const truncatedSearchRequests = searchString.slice(0, 3).join(", ");
    const renderedString =
      truncatedSearchRequests.length > 30 ? truncatedSearchRequests.substring(0, 30) + "..." : truncatedSearchRequests;

    return renderedString;
  };

  return (
    <section className="sp-wrapper">
      {showFilters && <FilterPanel clearFilters={() => resetFilters()} onApplyFilters={() => handleAppliedFilters()} />}

      {!showFilters && (
        <div className="sp-search-wrapper">
          <div className={inputWrapper}>
            <SearchAutoComplete
              selected={currentlySelected}
              setSelected={setCurrentlySelected}
              disabled={loading}
              setFinalTags={setFinalTags}
              clearTags={clearTags}
              setInvalidSearchMessage={() => setInvalidSearchMessage("")}
              setHideDialog={setHideDialog}
              clearSearch={clearSearch}
            />
          </div>
          <div className="sp-submit-wrapper">
            <IconButton
              iconProps={{ iconName: "Search" }}
              title="Search"
              aria-label="Search"
              onClick={() => handleSearch(searchTags)}
              disabled={loading}
            />
          </div>
        </div>
      )}

      {!showFilters && (
        <div ref={modalRef} className="ss-container">
          <div className="ss-container-left">
            <SaveSearch
              areSearchTagsEmpty={searchTags.length > 0 ? false : true}
              setIsAddNewSearchOpen={setIsAddNewSearchOpen}
              isAddNewSearchOpen={isAddNewSearchOpen}
              setIsViewSavedHistoryOpen={setIsViewSavedHistoryOpen}
              isViewSavedHistoryOpen={isViewSavedHistoryOpen}
              fetchSavedList={fetchSavedList}
              savedSearchesLength={savedList.savedSearches.length} //savedSearches.length > 0
            />

            {isAddNewSearchOpen && (
              <div className="ss-dropdown">
                <div className="ss-dropdown-top">
                  <input
                    onChange={(e) => setSavedSearchName(e.target.value)}
                    autoFocus
                    placeholder="Name Saved Search"
                  />
                  <div className="ss-dropdown-icons">
                    <TooltipHost content={"Confirm"} className="ss-tooltip">
                      <img
                        src={tickSaved}
                        alt="tick"
                        style={
                          savedSearchName.trim() !== ""
                            ? { cursor: "pointer" }
                            : { cursor: "not-allowed", opacity: 0.5 }
                        }
                        onClick={async () => {
                          if (savedSearchName.trim() !== "") {
                            try {
                              await sendSavedSearch(savedSearchName, selectedOrgs);
                              setSearchSavedSuccessful(true);
                              setIsAddNewSearchOpen(false);
                            } catch (err: any) {
                              setSavedErrorMessage(err.message);
                              setSearchSavedFail(true);
                              setIsAddNewSearchOpen(false);
                            }
                          }
                        }}
                      />
                    </TooltipHost>
                    <TooltipHost content={"Close"} className="ss-tooltip">
                      <img
                        src={crossSaved}
                        alt="cross"
                        onClick={() => {
                          setIsAddNewSearchOpen(false);
                        }}
                      />
                    </TooltipHost>
                  </div>
                </div>
              </div>
            )}
            {isViewSavedHistoryOpen && (
              <div style={isViewSavedHistoryOpen ? { height: "240px" } : {}} className="ss-dropdown">
                <>
                  <div className="ss-dropdown-view">
                    <p
                      onClick={() => {
                        setIsViewSavedHistoryOpen(false);
                        setIsViewAllSearchesOpen(!isViewAllSearchesOpen);
                        fetchSavedList(1, 100);
                      }}
                      className="ss-dropdown-view-title"
                    >
                      View Saved Searches ({!savedLoading ? savedList.totalCount : 0})
                    </p>
                  </div>
                  <hr />
                  {!savedLoading ? (
                    <div style={isViewSavedHistoryOpen ? { height: "186px" } : {}} className="ss-dropdown-list">
                      {savedList.savedSearches.length > 0 &&
                        savedList.savedSearches.map((item: any, index: number) => {
                          if (Object.keys(item).length === 0) {
                            return null;
                          }

                          return (
                            <div
                              key={`saved-search-${index}`}
                              onClick={() => {
                                handleSelectedSavedSearch(item.searchRequest);
                                setIsViewSavedHistoryOpen(false);
                              }}
                              className="ss-dropdown-list-item ss-select"
                            >
                              {item.searchRequest.length > 3 ? (
                                <TooltipHost content={item.searchRequest.join(", ")} className="ss-tooltip">
                                  <b>{item.name}</b>
                                  {/* <p>{item.searchRequest.join(", ")}</p> */}
                                  {/* <p>{item.searchRequest.slice(0, 3).join(", ")}</p> */}
                                  {/* <p>{item.searchRequest.slice(0, 3).join(", ").substring(0, 30)}</p> */}

                                  <p>{returnSavedSearch(item.searchRequest)}</p>
                                </TooltipHost>
                              ) : (
                                <>
                                  <b>{item.name}</b>
                                  <p>{returnSavedSearch(item.searchRequest)}</p>
                                </>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <div className="ss-spinner">
                      <Spinner ariaLive="assertive" labelPosition="top" size={SpinnerSize.large} />
                    </div>
                  )}
                </>
              </div>
            )}
            {!showFilters && !showFavourites && (
              <Link
                className="sp-filter-link"
                onClick={() => {
                  setIsViewSavedHistoryOpen(false);
                  setIsAddNewSearchOpen(false);
                  clearSearch();
                }}
                underline
              >
                Clear
              </Link>
            )}
          </div>
          {!showFilters && !showFavourites && (
            <Link
              // className={"sp-sort-link"}
              className={`${showBrandLimitWarning ? "sp-sort-link-off" : "sp-sort-link-on"} sp-sort-link`}
              // onClick={() => {
              //   setShowFilters(true);
              //   setInvalidSearchMessage("");
              // }}
              // onClick={() => {
              //   if (hideDialog) {
              //     setShowFilters(true);
              //     setInvalidSearchMessage("");
              //   }
              // }}
              onClick={() => !showBrandLimitWarning && setShowFilters(true)}
              underline
            >
              {`Sort & Filter ${`(${isBest ? altCount.length : filterCount.length})`}`}
            </Link>
          )}
        </div>
      )}

      {isViewAllSearchesOpen && (
        <div className="ss-history-page">
          <div
            className="ss-history-page-return"
            onClick={() => {
              setIsViewAllSearchesOpen(!isViewAllSearchesOpen);
            }}
          >
            <img src={chev} alt="back" />
            <h5>Back To Search</h5>
          </div>
          <h4 className="ss-history-page-title">Saved Searches</h4>
          <hr className="ss-history-page-hr" />
          <div className="ss-history-page-list">
            {savedList.savedSearches.length === 0 ? (
              <div className="ss-history-page-item">
                <div className="ss-history-page-item-left">
                  <p>No saved searches found</p>
                </div>
              </div>
            ) : (
              <>
                {!savedLoading ? (
                  savedList.savedSearches.map((item: any) => (
                    <div key={item.imageId} className="ss-history-page-item">
                      <div
                        className="ss-history-page-item-left"
                        onClick={() => {
                          handleSelectedSavedSearch(item.searchRequest);
                          setIsViewAllSearchesOpen(!isViewAllSearchesOpen);
                        }}
                      >
                        <TooltipHost content={item.searchRequest.join(", ")} className="ss-tooltip">
                          <div className="ss-history-page-item-title">{item.name}</div>
                          <p>{returnSavedSearch(item.searchRequest)}</p>
                        </TooltipHost>
                      </div>
                      <div onClick={() => deleteSearch(item.id)} className="ss-history-page-item-del">
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ))
                ) : (
                  <Spinner ariaLive="assertive" labelPosition="top" size={SpinnerSize.large} className="ss-spinner" />
                )}
              </>
            )}
          </div>
        </div>
      )}

      {searchSavedSuccessful && (
        <MessageBar messageBarType={MessageBarType.success}>Search saved successfully</MessageBar>
      )}

      {searchSavedFail && <MessageBar messageBarType={MessageBarType.error}>{savedErrorMessage}</MessageBar>}

      {invalidSearchMessage.length > 0 ? (
        <MessageBar messageBarType={MessageBarType.error}>{invalidSearchMessage}</MessageBar>
      ) : null}

      {/* <div className="sp-search-links">

        </div> */}

      {showLimitWarning && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline
          onDismiss={() => {
            setShowLimitWarning(false);
          }}
          dismissButtonAriaLabel="Close"
        >
          Please search with a maximum of 15 organisation names.
        </MessageBar>
      )}

      {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}

      <div className="sp-pivot-container">
        {" "}
        {!showFilters && (
          <Pivot
            aria-label="Search type"
            className="sp-pivot"
            selectedKey={currentTab}
            onLinkClick={(item?: PivotItem) => {
              if (item) {
                const currentTabKey = item.props.itemKey as SearchSource;
                setSelectedPhotos([]);
                setCurrentTab(currentTabKey);
                if (item.props.headerText === "Favorites") {
                  getUserFav();
                  setShowFavourites(true);
                  setAllSelected(false);
                  setIsRetainShapeSelected(false);
                } else if (item.props.headerText === "Logos") {
                  // getLogoList();
                  setShowFavourites(false);
                  setIsRetainShapeSelected(false);
                }
              }
            }}
          >
            <PivotItem
              headerText="Logos"
              // headerButtonProps={{
              //   "data-order": 1,
              //   "data-title": "Search WL Logo Store",
              //   disabled: loading,
              //   style: { color: loading ? "grey" : "inherit" },
              // }}
              itemKey="logos"
            ></PivotItem>
            <PivotItem
              headerText="Favorites"
              // headerButtonProps={{
              //   "data-order": 2,
              //   "data-title": "Search WL Logo Store",
              //   disabled: true,
              //   style: { color: loading ? "grey" : "grey" },
              // }}
              itemKey="favorites"
            ></PivotItem>
          </Pivot>
        )}
      </div>

      {/* Check if search started with no errors */}
      {!error && loading && <Busy />}

      {/* No logo's found after search */}
      {photos.length! === 0 && missingOrgList.length !== 0 && !loading ? (
        <NoPhotos hasQuery={searchQueryPhoto !== ""} />
      ) : null}

      {showMissingOrgsPopup && (
        <MissingOrgsPopup
          showMissingOrgsPopup={showMissingOrgsPopup}
          setMissingOrgsPopup={setMissingOrgsPopup}
          setRequestMissingLogos={() => setRequestMissingLogos(!requestMissingLogos)}
          handleMissingLogo={() => handleMissingLogo(missingOrgList)}
          missingOrgs={missingOrgList}
        />
      )}

      {requestMissingLogos && (
        <RequestMissingLogos
          requestMissingLogos={requestMissingLogos}
          setRequestMissingLogos={() => setRequestMissingLogos(false)}
          isRequestMissingLogosSuccessful={isRequestMissingLogosSuccessful}
          setIsRequestMissingLogosSuccessful={() => setIsRequestMissingLogosSuccessful(undefined)}
        />
      )}

      {!loading && !showFavourites && photos.length !== undefined && photos.length > 0 && !showFilters && (
        <section className="sp-photo-grid" onScroll={handleScroll}>
          <PhotoGrid
            photos={photos}
            selectedPhotos={selectedPhotos}
            setError={setError}
            handleSelectPhoto={handleSelectPhoto}
            onPhotosPlaced={(moreSpaces) => moreSpaces && appendNextPage()}
            currentImageOnTheGrid={currentImageOnTheGrid}
            setCurrentImageOnTheGrid={() => setCurrentImageOnTheGrid(currentImageOnTheGrid)}
            getUserFav={getUserFav}
            setFavPhotos={setFavPhotos}
            getLogoList={getLogoList}
            setPhotos={setPhotos}
          ></PhotoGrid>
        </section>
      )}

      {!loading && showFavourites && favPhotos.length !== undefined && favPhotos.length > 0 && !showFilters && (
        <section className="sp-photo-grid" onScroll={handleScroll}>
          <PhotoGrid
            photos={favPhotos}
            selectedPhotos={selectedPhotos}
            setError={setError}
            handleSelectPhoto={handleSelectPhoto}
            onPhotosPlaced={(moreSpaces) => moreSpaces && appendNextPage()}
            currentImageOnTheGrid={currentFavImageOnGrid}
            setCurrentImageOnTheGrid={() => setCurrentImageOnTheGrid(currentFavImageOnGrid)}
            setFavPhotos={setFavPhotos}
            getUserFav={getUserFav}
            getLogoList={getLogoList}
            setPhotos={setPhotos}
          ></PhotoGrid>
        </section>
      )}

      {!loading && showFavourites && favPhotos.length !== undefined && favPhotos.length === 0 && !showFilters && (
        <section className="sp-photo-grid" onScroll={handleScroll}>
          <p className="error-text">You have no Favourites.</p>
        </section>
      )}

      {(showFavourites ? favPhotos.length > 0 : photos.length > 0) && !loading && !showFilters && (
        <section className={bottomPanelClass}>
          <div className="sp-select-all">
            <Checkbox label="Select all" onChange={onChangeSelectAll} checked={isAllSelected} />
          </div>
          <div className="sp-retainShape">
            {hasFeatureGridInsert() && (
              <Checkbox
                label="Retain shape"
                checked={isRetainShapeSelected}
                onChange={() => setIsRetainShapeSelected(!isRetainShapeSelected)}
              />
            )}
          </div>
          <PrimaryButton className="sp-insert-btn" disabled={selectedPhotos.length === 0} onClick={showInsertGrid}>
            Insert
          </PrimaryButton>
        </section>
      )}

      {showGridOptions && (
        <GridOptionsPopup
          show={showGridOptions}
          setAlignment={setAlignment}
          alignment={alignment}
          isRetainShapeSelected={isRetainShapeSelected}
          setIsRetainShapeSelected={setIsRetainShapeSelected}
          setIsShapeSelected={setIsShapeSelected}
          retainShapeValidationErrorMessage={retainShapeValidationErrorMessage}
          setRetainShapeValidationErrorMessage={setRetainShapeValidationErrorMessage}
          isShapeSelected={isShapeSelected}
          itemCount={selectedPhotos.length}
          isDistributeHorizontallySelected={isDistributeHorizontallySelected}
          setDistributeHorizontallySelected={setDistributeHorizontallySelected}
          onInsert={(insertGridDimensions: GridDimensions) => {
            addImagesToDocument(selectedPhotos, insertGridDimensions);
            setShowGridOptions(false);
          }}
          onCancel={() => {
            setShowGridOptions(false), setRetainShapeValidationErrorMessage(false);
          }}
        />
      )}
      {retainShapeValidationErrorMessagePopUp && (
        <RetainShapeValidationErrorPopUp
          retainShapeValidationErrorMessagePopUp={retainShapeValidationErrorMessagePopUp}
          setRetainShapeValidationErrorMessagePopUp={setRetainShapeValidationErrorMessagePopUp}
        />
      )}

      <CustomModal isOpen={hideDialog} onClose={closeDialog} message={apiError.detailedMessages} type={errorType} />
    </section>
  );
}

export default SearchPage;
