import fetchPhotosViaWlStore from "../../Services/API/searchPhotos/searchWLStore";
import fetchPhotosViaSearchEngine from "../../Services/API/searchPhotos/searchEngine";
import { Photo, PhotoResults, SearchFilters, SearchSource } from "./ImageSearch.Interfaces";

export const pageSize = 60;

export async function fetchPhotos(
  query: string,
  page: number,
  pageSize: number,
  searchSource: SearchSource,
  searchFilters: SearchFilters
): Promise<PhotoResults> {
  if (searchSource === "wlStore") {
    // console.log("Fetching from wlStore");
    return fetchPhotosViaWlStore(query, page, pageSize, searchFilters);
  } else {
    return fetchPhotosViaSearchEngine(query, page, pageSize, searchFilters);
  }
}

function calculateIsLastPage(currentPage: number, pageLength: number, totalPhotos: number) {
  return currentPage * pageLength >= totalPhotos;
}

export const returnOrgLogos = async (
  query: string,
  page: number,
  searchSource: SearchSource,
  searchFilters: SearchFilters
) => {
  let error = undefined;
  let photos: Photo[] = [];
  let isLastPage = false;
  let message = "";
  let missingOrgs: string[] = [];
  let photoCount = 0;
  try {
    // console.log("returnOrgLogos", query, page, searchSource, searchFilters);
    // console.log(`Fetching page ${page} of ${pageSize} logos from ${searchSource}`);
    const photoResults = await fetchPhotos(query, page, pageSize, searchSource, searchFilters);
    // console.log("RESULTS", photoResults);
    isLastPage = calculateIsLastPage(page, pageSize, photoResults.photoCount) || searchSource === "searchEngine"; // Search engine only returns 1 page for now
    photos = photoResults.photos;
    photoCount = photoResults.photoCount;
    message = photoResults.message;
    missingOrgs = photoResults.missingOrgs;
  } catch (err: any) {
    // error = err;
    // // console.log('error', error);
    // console.error(err);
  }
  // console.log(`Returning ${photoCount} logos from ${searchSource}`);
  return { error, photos, isLastPage, photoCount, message, missingOrgs };
};
