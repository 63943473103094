import { configureStore } from "@reduxjs/toolkit";
import { FilterSlice } from "../features/filterSlice";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { Orgs } from "../features/selectedOrgsSlice";
import { AuthStatusSlice } from "../features/authStatus";
import { SuggestedOrgs } from "../features/suggestedOrgs";
import { selectedTabSlice } from "../features/autoCompleteSuggestionHeader";
import { insertedLogoFormatSlice } from "../features/insertedLogoFormat";
import missingOrgs, { missingOrgsSlice } from "../features/missingOrgs";
import { carouselPhotoIndexSlice } from "../features/carouselPhotoIndex";
import { AmplifyConfiguredStatusSlice } from "../features/amplifyConfiguredStatus";
import { LogoPaddingSlice } from "../features/logoPadding";
import { ApiErrorSlice } from "../features/apiError";

export const store = configureStore({
  reducer: {
    filters: FilterSlice.reducer,
    Orgs: Orgs.reducer,
    authStatus: AuthStatusSlice.reducer,
    suggestedOrgs: SuggestedOrgs.reducer,
    autoCompleteTabHeader: selectedTabSlice.reducer,
    insertedLogoFormat: insertedLogoFormatSlice.reducer,
    missingOrgsFormatRedux: missingOrgsSlice.reducer,
    amplifyConfiguredRedux: AmplifyConfiguredStatusSlice.reducer,
    carouselPhotoIndexRedux: carouselPhotoIndexSlice.reducer,
    logoPaddingRedux: LogoPaddingSlice.reducer,
    apiErrorRedux: ApiErrorSlice.reducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
