const wlConfig = {
  apiEndpoint: "https://api.wl-dev-logostore.com",
  configurationEndpoint: "https://api4internal.wl-dev-logostore.com",
  region:"us-east-1",
  userPoolId:"us-east-1_IQZWW6Scv",
  cognitoDomain:"wl-logostore-dev.auth.us-east-1.amazoncognito.com",
  scope:["email","profile","openid"],
  responseType:"code",
  version:"November 2024.1",
};
export default wlConfig;
