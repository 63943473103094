import {
  Dialog,
  DialogType,
  IBasePicker,
  IInputProps,
  ITag,
  Icon,
  Spinner,
  SpinnerSize,
  TagPicker,
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { nanoid } from "nanoid";
import { CustomTag, SearchAutoCompleteProps } from "./SearchAutoComplete.Interfaces";
import { Org } from "../../Services/API/orgNames/fetchOrgNames.Interfaces";
import { fetchOrgNames } from "../../Services/API/orgNames/fetchOrgNames";
import OrgSuggestions from "../OrgSuggestions/OrgSuggestions/OrgSuggestions";
import { fuzzySearch } from "../../Services/API/fuzzySearch/fuzzySearch";
import { dialogStyles, metaDataType, PivotStyles, selectedTabValue } from "./Styles/SearchAutoCompleteStyles";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { addSelectedSuggestions, resetSelectedSuggestions } from "../../Redux/features/suggestedOrgs";
import "./Styles/SearchAutoComplete.css";
// import { UpdateSelectedtabStatus } from "../../Redux/features/autoCompleteSuggestionHeader";
import { SuggestionsHeaderText } from "../OrgSuggestions/AutoCompleteSuggestionHeader";

export function SearchAutoComplete({
  selected,
  setSelected,
  disabled,
  clearTags,
  setInvalidSearchMessage,
  setFinalTags,
  clearSearch,
}: SearchAutoCompleteProps) {
  const [filteredResults, setFilteredResults] = useState<ITag[]>([]);
  const [bulkAdd, setBulkAdd] = useState<boolean>(false);
  const [separatorUsed, setSeparatorUsed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [matchingOrgsSuggestions, setMatchingOrgsSuggestions] = useState<any[]>([]);
  const [fuzzySearchData, setFuzzySearchData] = useState<any[]>([]);
  const [nonMatchingOrgs, setNonMatchingOrgs] = useState<any[]>([]);
  const [searchOrderSuggestions, setSearchOrderSuggestions] = useState<any[]>([]);
  const [suggestionsPage, setSuggestionsPage] = useState<number>(0);
  const [currentTags, setCurrentTags] = useState<any>([]);
  const [didYouMeanNeeded, setDidYouMeanNeeded] = useState<any>(false);
  const [autoCompleteList, setAutoCompleteList] = useState<any>([]);
  const tagPickerRef = useRef<IBasePicker<ITag>>(null);
  const dispatch = useAppDispatch();
  const newOrgs = useAppSelector((state) => state.suggestedOrgs.newOrgs);
  const isAlias = useAppSelector((state) => state.filters.searchFilters.isAlias);
  // const [selectedTab, setSelectedTab] = useState<string>(isAlias ? "StockSymbol" : "Name");
  const selectedTab = useAppSelector((state) => state.autoCompleteTabHeader.selectedTab);
  const brandLimitReached = useAppSelector((state: any) => state.apiErrorRedux.brandLimitReached);

  //Loading Icon component
  function Busy() {
    return <Spinner label="Checking..." ariaLive="assertive" labelPosition="bottom" size={SpinnerSize.large} />;
  }

  //UseEffects
  useEffect(() => {
    dispatch(resetSelectedSuggestions());
  }, []);

  useEffect(() => {
    if (!didYouMeanNeeded && selected.length > 0) {
      setFinalTags(selected);
    }

    if (selected.length === 0) {
      setFinalTags(selected);
    }
  }, [filteredResults, selected]);

  useEffect(() => {
    if (brandLimitReached) {
      if (clearSearch) {
        clearSearch();
      }
    }

    if (clearTags) {
      setSelected([]);
    }
  }, [clearTags, brandLimitReached]);

  // useEffect(() => {
  // }, [selectedTab]);

  useEffect(() => {
    if (tagPickerRef.current) {
      handleOnFocus();
    }
    return () => {};
  }, [tagPickerRef, selected]);

  const handleOnFocus = (): void => {
    if (selected.length > 0) {
      const lastTagName = CSS.escape(selected[selected?.length - 1].name);
      const containerElement = document.querySelectorAll(`span[title="${lastTagName}"]`);
      const lastTag = containerElement?.item(containerElement.length - 1);

      if (containerElement && lastTag !== null) {
        lastTag.scrollIntoView({ block: "center" });
      }
    }
  };

  // Input props for the tag picker including a search logo
  const inputProps: IInputProps = useMemo(
    () => ({
      placeholder: "Enter Search Term",
    }),
    []
  );

  //Single Search
  const convertedOrgs = (orgs: any[]): CustomTag[] => {
    const result: any = [];

    orgs.map((org) => {
      const searchFieldName = org.searchFieldName;
      const items = org.items;
      if (items.length === 0 && bulkAdd === false) {
        //created a suggestion with info "no match found"
        result.push({
          searchFieldName,
          key: nanoid(),
          name: "No match found",
          isNewItem: false,
        });
      } else {
        result.push(
          ...items.map((item: string) => ({
            searchFieldName,
            key: nanoid(),
            name: item,
            isNewItem: false,
          }))
        );
      }
    });

    return result;
  };
  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  const pickerSuggestionsProps: any = {
    suggestionsHeaderText: <SuggestionsHeaderText />,
  };
  const onRenderSuggestionsItem = (props: any): JSX.Element => {
    const userAdded = props.isNewItem;

    let elementToRender: JSX.Element = <div className="orgsRendered"></div>;

    if (userAdded) {
      elementToRender = (
        <>
          <div className="ac-new-item" key={props.key}>
            <p>{props.name}</p>
          </div>
          <Icon
            aria-label="ReturnKey"
            iconName="ReturnKey"
            className="ac-icon"
            // onClick={() => handleBulkSearch([props.name])}
          />
        </>
      );
    } else if (
      (selectedTab === selectedTabValue.Name && props.searchFieldName === metaDataType.Name) ||
      (selectedTab === selectedTabValue.Tag && props.searchFieldName === metaDataType.Tag) ||
      (selectedTab === selectedTabValue.StockSymbol && props.searchFieldName === metaDataType.StockSymbol) ||
      (selectedTab === selectedTabValue.Isin && props.searchFieldName === metaDataType.Isin)
    ) {
      elementToRender = (
        <div className="ac-existing-item" key={props.key}>
          <p>{props.name}</p>
        </div>
      );
    }

    return elementToRender;
  };
  const filterSuggestedTags = useCallback(
    async (filterText: string, tagList: ITag[]) => {
      // if results contain a separator then we are doing a bulk add
      if (filterText.indexOf(",") > -1 || filterText.indexOf(";") > -1) {
        setBulkAdd(true);
        setSeparatorUsed(true);
      } else {
        setBulkAdd(false);
        setSeparatorUsed(false);
      }

      const orgNameResults = await fetchOrgNames(filterText, isAlias);
      setAutoCompleteList(orgNameResults);
      const convertedResults = convertedOrgs(orgNameResults.orgs);
      const existingMatches = filterText
        ? convertedResults
            // .filter((tag) => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0)
            .filter((tag) => !listContainsTagList(tag, tagList))
        : [];

      const filteredOrgs = existingMatches.some((a) => a.name === filterText)
        ? existingMatches
        : [{ key: nanoid(), name: filterText, isNewItem: true } as ITag].concat(existingMatches);

      setFilteredResults(tagList);

      return filteredOrgs;
    },
    [fetchOrgNames, convertedOrgs, listContainsTagList]
  );

  const getTextFromItem = (item: ITag) => item.name;

  //Function to split matching and DYM suggestion orgs in bulk search
  const fetchSuggestions = async (logoList: string[]) => {
    setLoading(true);

    let matchingOrgs: any = [];
    let nonMatchingOrgs: any = [];
    let filteredNonMatchingOrgs: any = [];
    const fuzzySearchData = await fuzzySearch([...logoList], isAlias);
    setSearchOrderSuggestions(logoList);
    setFuzzySearchData(fuzzySearchData.data.fuzzySearchResponses);

    fuzzySearchData.data.fuzzySearchResponses.forEach((org: any) => {
      // Sort between matching and non-matching orgs
      if (org.isExactMatch === true) {
        matchingOrgs.push(org.exactMatchDetail);
      } else {
        // Filter out and store the non-matching orgs with no suggestions
        org.noExactMatchDetail.suggestions.forEach((item: any) => {
          if (item.items.length > 0) {
            filteredNonMatchingOrgs.push(org.noExactMatchDetail);
          }
        });
      }
    });

    const filteredArray = filteredNonMatchingOrgs.filter((obj: any, index: number, arr: any) => {
      return arr.map((mapObj: any) => mapObj.heading).indexOf(obj.heading) === index;
    });
    setNonMatchingOrgs(filteredArray);
    setMatchingOrgsSuggestions(matchingOrgs);

    // Activate DYM pop-up
    if (filteredArray.length > 0) {
      filteredArray.forEach((org: any) => {
        dispatch(
          addSelectedSuggestions({
            selectedSuggestions: {
              searchName: org.heading,
              searchFieldName:
                org.suggestions[0].items.length > 0 ? org.suggestions[0].metadataName : org.suggestions[1].metadataName,
              suggestions:
                org.suggestions[0].items.length > 0
                  ? [org.suggestions[0].items[0].searchKeywordToUse]
                  : [org.suggestions[1].items[0].searchKeywordToUse],
            },
          })
        );
      });

      setDidYouMeanNeeded(true);
    } else {
      setDidYouMeanNeeded(false);
    }

    const allOrgs = [...nonMatchingOrgs, ...matchingOrgs];
    setLoading(false);

    if (didYouMeanNeeded) {
      return allOrgs;
    } else {
      let directMatchInDYMSearch: any[] = [];
      let searchFieldName: string = "";

      fuzzySearchData.data.fuzzySearchResponses.forEach((org: any) => {
        if (org.isExactMatch === true) {
          directMatchInDYMSearch.push({
            key: nanoid(),
            name: org.exactMatchDetail.searchKeywordToUse,
            searchFieldName: org.exactMatchDetail.metadataName,
            isNewItem: true,
          });
        } else {
          if (isAlias) {
            if (org.noExactMatchDetail.suggestions[0].items.length > 0) {
              searchFieldName = org.noExactMatchDetail.suggestions[0].metadataName;
            } else {
              if (org.noExactMatchDetail.suggestions[1].items.length > 0) {
                searchFieldName = org.noExactMatchDetail.suggestions[1].metadataName;
              } else {
                searchFieldName = "";
              }
            }
          } else {
            if (org.noExactMatchDetail.suggestions[0].items.length > 0) {
              searchFieldName = org.noExactMatchDetail.suggestions[0].metadataName;
            } else {
              if (org.noExactMatchDetail.suggestions[1].items.length > 0) {
                searchFieldName = org.noExactMatchDetail.suggestions[1].metadataName;
              } else {
                searchFieldName = "";
              }
            }
          }

          directMatchInDYMSearch.push({
            key: nanoid(),
            name: org.noExactMatchDetail.heading,
            searchFieldName: searchFieldName,
            isNewItem: true,
          });
        }
      });

      return directMatchInDYMSearch;
    }
  };

  //Function to handle bulk search
  const handleBulkSearch = async (filterText: string[]) => {
    // filtering bulk search items to remove empty tags and duplicates
    const commaSplit = filterText
      .join(",")
      .split(/(?:,|;)+/)
      .filter((item: string) => item.trim() !== "");
    let filteredCommaSplit = Array.from(
      new Set(
        commaSplit.map((item: string) => {
          return item.trim();
        })
      )
    );
    if (filteredCommaSplit.length > 0) {
      const fetchRes = await fetchSuggestions(filteredCommaSplit);
      // we compare previous tags with tags in bulk search and remove duplicates
      const allTags = [...selected, ...fetchRes];

      const filteredTags = allTags.filter((obj, index, arr) => {
        return arr.map((item) => item.name.toLowerCase()).indexOf(obj.name.toLowerCase()) === index;
      });
      // add the new tags to the selected list
      // currentTags are all of the tags that were present before this bulk search,
      setCurrentTags([...selected]);
      setSelected([...filteredTags]);
      setBulkAdd(false);
    }
  };

  //Discard orgs when user cancels provided suggestion in DYM
  const discardDYMSuggestion = () => {
    // Remove non-matching orgs from the list
    const excludedNonMatching = searchOrderSuggestions.filter((item) => {
      return !nonMatchingOrgs.find((org) => {
        return item === org.heading;
      });
    });

    let matchingOrgs: any = [];

    // If bulk add is aborted by clicking cancel in the pop-up, then insert the matching name's only
    fuzzySearchData.forEach((org: any) => {
      excludedNonMatching.forEach((item: any) => {
        if (org.searchKeywordGiven.toLowerCase() === item.toLowerCase()) {
          matchingOrgs.push({
            key: nanoid(),
            name: item.toLowerCase(),
            searchFieldName:
              org.exactMatchDetail !== null
                ? org.exactMatchDetail.metadataName[0].toUpperCase() + org.exactMatchDetail.metadataName.substring(1)
                : "",
            isNewItem: true,
          });
        }
      });
    });

    // We need to check all tags for duplicates in case some matching names's are already present
    const allTags = [...currentTags, ...matchingOrgs];

    const filteredTags = allTags.filter((obj, index, arr) => {
      return arr.map((item) => item.name.toLowerCase()).indexOf(obj.name.toLowerCase()) === index;
    });

    setSelected(filteredTags);
  };
  // Adds orgs when user selects  suggestion in DYM
  const handleAppliedBulkSearch = () => {
    let bulkSearchResultArray: any[] = [];
    let dymSelectedSuggestions = [...newOrgs];
    searchOrderSuggestions.map((orgs: string) => {
      fuzzySearchData.forEach((data: any) => {
        if (data.searchKeywordGiven === orgs) {
          if (data.isExactMatch === true) {
            bulkSearchResultArray.push({
              key: nanoid(),
              name: data.exactMatchDetail.searchKeywordToUse,
              searchFieldName: data.exactMatchDetail.metadataName,
            });
          } else {
            const didYouMeanSuggestion = dymSelectedSuggestions.find(
              (dymSuggestion: any) => dymSuggestion.searchName === orgs
            );
            if (didYouMeanSuggestion) {
              if (didYouMeanSuggestion.suggestions.length === 0) {
                bulkSearchResultArray.push({
                  key: nanoid(),
                  name: didYouMeanSuggestion.searchName,
                  searchFieldName: didYouMeanSuggestion.searchFieldName,
                });
              } else {
                bulkSearchResultArray.push({
                  key: nanoid(),
                  name: didYouMeanSuggestion.suggestions[0],
                  searchFieldName: didYouMeanSuggestion.searchFieldName,
                });
              }
            } else {
              bulkSearchResultArray.push({
                key: nanoid(),
                name: orgs,
                searchFieldName: "",
              });
            }
          }
        }
      });
    });
    const allTags = [...currentTags, ...bulkSearchResultArray];
    const filteredTags = allTags.filter((obj, index, arr) => {
      return arr.map((item) => item.name.toLowerCase()).indexOf(obj.name.toLowerCase()) === index;
    });

    setSelected(filteredTags);
    setDidYouMeanNeeded(false);
    setSuggestionsPage(0);
    dispatch(resetSelectedSuggestions());
  };

  const onItemSelected = useCallback(
    (item: CustomTag) => {
      const tagSelectedFromAutoCompleteSuggestion = autoCompleteList.orgs.some((org: any) => {
        return org.items.indexOf(item.name) !== -1;
      });

      if (item.name === "No match found") {
        return null;
      }
      if (item.name.length > 0) {
        setInvalidSearchMessage();
      }

      if (!tagSelectedFromAutoCompleteSuggestion || bulkAdd) {
        handleBulkSearch([item.name]);
        setDidYouMeanNeeded(false);
      } else {
        // Check for duplicates and ignore if found or if the item contains a comma
        const match = selected.some((tag: any) => tag.name.toLowerCase() === item.name.toLowerCase());
        if (match || separatorUsed) {
          return null;
        }

        // Otherwise add the item to the selected list
        setSelected([...selected, item]);
        return item;
      }
      return null;
    },
    [selected, bulkAdd, handleBulkSearch]
  );

  return (
    <>
      {loading && (
        <Dialog
          hidden={!loading}
          dialogContentProps={{
            type: DialogType.normal,
            styles: dialogStyles,
          }}
        >
          <div className="ac-loading-container">
            <Busy />
          </div>
        </Dialog>
      )}

      {nonMatchingOrgs && didYouMeanNeeded && (
        <Dialog
          hidden={!didYouMeanNeeded}
          dialogContentProps={{
            type: DialogType.normal,
            styles: dialogStyles,
          }}
        >
          {nonMatchingOrgs.map((item: any, index: number) => {
            if (index === suggestionsPage) {
              return (
                <OrgSuggestions
                  name={item.heading}
                  totalPage={nonMatchingOrgs.length}
                  suggestionsList={item.suggestions}
                  index={index}
                  suggestionsPage={suggestionsPage}
                  setSuggestionsPage={setSuggestionsPage}
                  discardDYMSuggestion={discardDYMSuggestion}
                  setDidYouMeanNeeded={setDidYouMeanNeeded}
                  setLoading={setLoading}
                  handleAppliedBulkSearch={handleAppliedBulkSearch}
                  key={index}
                />
              );
            }
            return null;
          })}
        </Dialog>
      )}

      <TagPicker
        componentRef={tagPickerRef}
        removeButtonAriaLabel="Remove"
        selectionAriaLabel="Organisations"
        onResolveSuggestions={(e: string): any => filterSuggestedTags(e, selected)}
        getTextFromItem={getTextFromItem}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        disabled={disabled}
        onItemSelected={(e: any) => onItemSelected(e)}
        pickerSuggestionsProps={bulkAdd ? null : pickerSuggestionsProps}
        selectedItems={selected}
        onChange={(items: any) => {
          setSelected(items.filter((item: ITag) => item.name.trim() !== ""));
        }}
        inputProps={{
          ...inputProps,
          id: "orgPicker",
        }}
      />
    </>
  );
}
