import React, { useEffect, useRef } from "react";
import { PhotoGridProps } from "../ImageSearch.Interfaces";
import { PhotoCell } from "../PhotoCell/PhotoCell";
import "./Styles/PhotoGrid.css";

export function PhotoGrid({
  photos,
  selectedPhotos,
  handleSelectPhoto,
  setError,
  onPhotosPlaced,
  currentImageOnTheGrid,
  setCurrentImageOnTheGrid,
  // selectedTab,
  getUserFav,
  setFavPhotos,
  getLogoList,
  setPhotos,
  isFavTab,
}: PhotoGridProps) {
  useEffect(() => {
    // Check if there's more spaces than current photo batch for a nicer UI experience -
    // we can trigger the next page of photos either on more gaps or when the user scrolls to the bottom
    const grid = document.querySelector(`.pg-grid`);
    const cell = document.querySelector(`.pg-grid > div`);
    if (grid && cell) {
      const gridHeight = grid.clientHeight;
      const moreSpaces = grid.parentElement!.clientHeight > gridHeight - cell.clientHeight;
      onPhotosPlaced(moreSpaces);
    }
  }, [photos]);
  // console.log(selectedTab);
  const items = photos.map((data: any, index: number) => {
    return (
      <div key={`saved-search-${index}`} className="pg-cell-container">
        <PhotoCell
          // selectedTab={"favorites"}
          key={data.id}
          photo={data}
          onClick={(photo) => handleSelectPhoto(photo)}
          selectedPhotos={selectedPhotos}
          setError={setError}
          currentImageOnTheGrid={currentImageOnTheGrid}
          setCurrentImageOnTheGrid={setCurrentImageOnTheGrid}
          getUserFav={getUserFav}
          getLogoList={getLogoList}
          setFavPhotos={setFavPhotos}
          setPhotos={setPhotos}
          isFavTab={isFavTab}
        />
      </div>
    );
  });

  return (
    <>
      <div className="pg-grid">{items}</div>
    </>
  );
}
