import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  PrimaryButton,
  ResponsiveMode,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useHistory } from "react-router-dom";
import { uploadLogoRequest } from "../../Services/API/UploadLogo/uploadLogoRequest";
import "./Styles/AddLogo.css";
import AddLogoRequestConfirmation from "./AddLogoRequestConfirmation";
import AddLogoRequestError from "./AddLogoRequestError";
import { UpdateMissingOrgsList } from "../../Redux/features/missingOrgs";
import * as Yup from "yup";
import { acceptedFileTypes, addLogoInputTypeOptions, domainRegex, imageSizeLimit } from "../../Util/AddLogoHelpers";
const AddLogoForm = ({
  org,
  onSubmit,
  onPrevious,
  onNext,
  currentOrgIndex,
  missingOrgList,
  submittedValues,
}: {
  org: String;
  onSubmit: any;
  onPrevious: any;
  onNext: any;
  currentOrgIndex: number;
  missingOrgList: string[];
  submittedValues: any[];
}) => {
  const history = useHistory();
  const [charCount, setCharCount] = useState(4000);
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .trim() // trim leading and trailing whitespace
      .min(1, "Organization Name is required.")
      .required("Organization Name is required.")
      .matches(/^[^\/\\:?,<>|*]*$/, `Brand name cannot contain the following characters \\ / : ? , < > | *`),

    // Domain: Yup.string().required("Domain URL is required.").matches(domainRegex, "Please provide a valid URL."),
    Domain: Yup.string()
      .trim() // trim leading and trailing whitespace
      .min(1, "Domain URL is required.")
      .required("Domain URL is required.")
      .matches(domainRegex, "Please provide valid domain."),
    ImageUrl: Yup.string()
      .when("LogoType", {
        is: "URL",
        then: (schema) => schema.url("Please provide a valid URL"),
        otherwise: (schema) => schema.notRequired(),
      })
      .matches(/\.(jpg|jpeg|png|svg)$/, "Please provide URL to a valid PNG, SVG, JPG or JPEG image."),
    imageFile: Yup.mixed().when("LogoType", (values: any[], schema: Yup.Schema) => {
      const LogoType = values[0]; // assuming LogoType is the first value in the array
      return LogoType === "File"
        ? schema
            .test("File_Type", "Please upload a PNG, SVG, JPG or JPEG image.", function (value: any) {
              if (value) {
                return acceptedFileTypes.includes(value.type || "");
              }
              return true;
            })
            .test("File_Size", "Please provide image size less than 1MB.", function (value: any) {
              if (value) {
                return value.size < imageSizeLimit;
              }
              return true;
            })
        : // .required("File is required.")
          schema.notRequired();
    }),
  });

  return (
    <Formik
      initialValues={{
        Name: submittedValues[currentOrgIndex]?.Name || org,
        Domain: submittedValues[currentOrgIndex]?.Domain || "",
        imageFile: "",
        Comments: submittedValues[currentOrgIndex]?.Comments || "",
        LogoType: "URL",
        ImageUrl: submittedValues[currentOrgIndex]?.ImageUrl || "",
        IsImageFileGiven: submittedValues[currentOrgIndex]?.IsImageFileGiven || false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {(formik: any) => (
        <form className="page" onSubmit={formik.handleSubmit}>
          <div className="addLogoPage-heading">
            <p>Add Logo</p>
            {missingOrgList.length > 0 ? (
              <label className="pageNumber form-field-label ">
                {currentOrgIndex + 1}/{missingOrgList.length}
              </label>
            ) : (
              <></>
            )}
          </div>

          <div className="inputFields">
            <label className="form-field-label">
              <span className="asterisk-red">*</span>Organization Name
            </label>
            <Field type="text" name="Name" />
            <ErrorMessage name="Name" component="div" className="addLogo-errorMsg" />
          </div>
          <div className="inputFields">
            <label className="form-field-label form-field-spacing" htmlFor="Domain">
              <span className="asterisk-red">*</span>Domain URL
            </label>
            <Field type="text" name="Domain" />
            <ErrorMessage name="Domain" component="div" className="addLogo-errorMsg" />
          </div>

          <div className="logoType-dropDown">
            <label className="form-field-label">Add Logo</label>
            <Dropdown
              defaultValue="URL"
              placeholder="URL"
              options={addLogoInputTypeOptions}
              responsiveMode={ResponsiveMode.large}
              onChange={(_event, addLogoInputTypeOptions) => {
                formik.setFieldValue("LogoType", addLogoInputTypeOptions?.key);
                if (addLogoInputTypeOptions?.key === "URL") {
                  formik.setFieldValue("imageFile", "");
                } else {
                  formik.setFieldValue("ImageUrl", "");
                }
              }}
              selectedKey={formik.values.LogoType}
              className="LogoType"
            />
          </div>
          <div className="logoType-Value">
            <label className="form-field-label">{formik.values.LogoType === "URL" ? "Image URL" : "Image File"}</label>
          </div>
          {String(formik.values.LogoType) === "URL" ? (
            <div className="inputFields">
              <Field multiline rows={3} as="textarea" className="logoType-URL" type="text" name="ImageUrl" />
              {formik.touched.ImageUrl && formik.errors.ImageUrl && (
                <ErrorMessage name="ImageUrl" component="div" className="addLogo-errorMsg" />
              )}
            </div>
          ) : (
            <div>
              <input
                type="file"
                name="imageFile"
                accept=".jpg, .jpeg, .png, .svg"
                onChange={(e) => {
                  if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                    const selectedFile = e.currentTarget.files[0];
                    formik.setFieldValue("imageFile", selectedFile);
                  } else {
                    formik.setFieldValue("imageFile", "");
                  }
                }}
                className="uploadNewLogo form-control"
                id="customFile"
              />
              {formik.touched.imageFile && formik.errors.imageFile && (
                <ErrorMessage name="imageFile" component="div" className="addLogo-errorMsg" />
              )}
            </div>
          )}

          <div className="inputFields">
            <label className="form-field-label form-field-spacing">Comments</label>
            <Field
              multiline
              maxLength={4000}
              rows={7}
              as="textarea"
              name="Comments"
              onChange={(e: any) => {
                formik.handleChange(e);
                const inputText = e.target.value;
                setCharCount(4000 - inputText.length);
              }}
            />
            <p className="char-counts">{charCount} characters remaining</p>
            <ErrorMessage name="Comments" component="div" />
          </div>
          <div className="btns-container support-footer">
            {missingOrgList.length > 0 ? (
              <button
                type="button"
                className={currentOrgIndex === 0 ? "org-suggest-nav-btn btn-disabled" : "org-suggest-nav-btn"}
                onClick={() => onPrevious(formik.dirty)}
              >
                <span className="org-suggest-nav-icon">{`<`}</span>
              </button>
            ) : (
              <></>
            )}
            <PrimaryButton type="submit" disabled={formik.isSubmitting}>
              Submit
            </PrimaryButton>
            <DefaultButton
              text={missingOrgList.length == 0 ? "Cancel" : "Close"}
              onClick={() => {
                history.goBack(), dispatch(UpdateMissingOrgsList({ missingOrgs: [] }));
              }}
              className="cancelButton"
            />
            {missingOrgList.length > 0 ? (
              <button
                className={
                  currentOrgIndex === missingOrgList.length - 1
                    ? "org-suggest-nav-btn btn-disabled"
                    : "org-suggest-nav-btn"
                }
                type="button"
                onClick={() => onNext(formik.dirty)}
              >
                <span className="org-suggest-nav-icon">{`>`}</span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

const AddLogo = () => {
  const missingOrgList = useAppSelector((state) => state.missingOrgsFormatRedux.missingOrgs);
  const apiError = useAppSelector((state) => state.apiErrorRedux.apiError);
  const [currentOrgIndex, setCurrentOrgIndex] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isNextSelected, setNextSelected] = useState(false);
  const [isPreviousSelected, setPreviousSelected] = useState(false);
  const [submittedValues, setSubmittedValues] = useState<any[]>([]);
  const [addLogoRequestResponse, setAddLogoRequestResponse] = useState<any>([]);
  const [error, setError] = useState(false);
  const [submittedOrg, setSubmittedOrg] = useState("");
  const [errorMsg, setErrorMsg] = useState([]);
  const [errorStatusCode, setErrorStatusCode] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const warningMsgDialogContentProps = {
    type: DialogType.normal,
    subText: "There is unsaved data in the form. Do you want to continue without submitting?",
  };
  const handleSubmit = ({ Name, Domain, imageFile, Comments, LogoType, ImageUrl, IsImageFileGiven }: any) => {
    if (LogoType === "File" && imageFile !== "") {
      IsImageFileGiven = true;
    }

    const formData = {
      Name,
      Domain,
      imageFile,
      Comments,
      LogoType,
      ImageUrl,
      IsImageFileGiven,
    };

    setLoading(true);
    uploadLogoRequest(formData)
      .then((addLogoResponse) => {
        if (addLogoResponse.success === false) {
          setLoading(false);
          setError(true);
        } else {
          setLoading(false);
          setAddLogoRequestResponse(addLogoResponse);
          setSubmittedValues((prevSubmittedValues) => {
            const updatedValues = [...prevSubmittedValues];
            updatedValues[currentOrgIndex] = formData;
            return updatedValues;
          });

          setShowSuccess(true);
          setNextSelected(false);
          setPreviousSelected(false);
          setShowWarning(false);
          setSubmittedOrg(Name);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setErrorMsg(error.response.data);
        setErrorStatusCode(error.response.status);
        setError(error);
        setLoading(false);
      });
  };

  const handleNext = (isDataUnsaved: boolean) => {
    setPreviousSelected(false);
    setNextSelected(true);
    if (!showSuccess && isDataUnsaved) {
      setShowWarning(true);
    } else {
      if (currentOrgIndex < missingOrgList.length - 1) {
        setCurrentOrgIndex(currentOrgIndex + 1);
      }
    }
  };

  const handlePrevious = (isDataUnsaved: boolean) => {
    setPreviousSelected(true);
    setNextSelected(false);
    if (!showSuccess && isDataUnsaved) {
      setShowWarning(true);
    } else {
      if (currentOrgIndex > 0) {
        setCurrentOrgIndex(currentOrgIndex - 1);
      }
    }
  };

  const handleWarningConfirmation = () => {
    // setCurrentOrgIndex(currentOrgIndex + 1);
    if (isNextSelected) {
      setCurrentOrgIndex(currentOrgIndex + 1);
      setNextSelected(false);
    } else if (isPreviousSelected) {
      setCurrentOrgIndex(currentOrgIndex - 1);
      setPreviousSelected(false);
    }
    setShowWarning(false);
  };

  const handleSubmitConfirmation = () => {
    setShowSuccess(!showSuccess);
    if (currentOrgIndex < missingOrgList.length - 1) {
      setCurrentOrgIndex(currentOrgIndex + 1);
    } else {
      if (currentOrgIndex === missingOrgList.length - 1 || currentOrgIndex === 0) {
        dispatch(UpdateMissingOrgsList({ missingOrgs: [] }));
        history.push("/search");
      }
    }
  };
  const handleWarningCancel = () => {
    setShowWarning(false);
  };
  function Busy() {
    return (
      <Spinner
        className="support-spinner"
        label="Please wait..."
        ariaLive="assertive"
        labelPosition="bottom"
        size={SpinnerSize.large}
      />
    );
  }
  return (
    <div>
      {loading && (
        <Dialog
          hidden={!loading}
          onDismiss={() => history.goBack()}
          dialogContentProps={{
            type: DialogType.normal,
          }}
        >
          <Busy />
        </Dialog>
      )}
      {missingOrgList.length > 0 ? (
        <>
          <AddLogoForm
            key={currentOrgIndex}
            org={missingOrgList[currentOrgIndex]}
            onSubmit={handleSubmit}
            onPrevious={handlePrevious}
            onNext={handleNext}
            currentOrgIndex={currentOrgIndex}
            missingOrgList={missingOrgList}
            submittedValues={submittedValues}
          />

          {showWarning && (
            <div>
              <Dialog hidden={!showWarning} dialogContentProps={warningMsgDialogContentProps}>
                <DialogFooter>
                  <PrimaryButton onClick={handleWarningConfirmation} text="Yes" />
                  <DefaultButton onClick={() => handleWarningCancel()} text="No" />
                </DialogFooter>
              </Dialog>
            </div>
          )}
        </>
      ) : (
        <>
          <AddLogoForm
            key={0}
            org={""}
            onSubmit={handleSubmit}
            onPrevious={handlePrevious}
            onNext={handleNext}
            currentOrgIndex={0}
            missingOrgList={[]}
            submittedValues={submittedValues}
          />

          {showWarning && (
            <div>
              <Dialog hidden={!showWarning} dialogContentProps={warningMsgDialogContentProps}>
                <DialogFooter>
                  <PrimaryButton onClick={handleWarningConfirmation} text="Yes" />
                  <DefaultButton onClick={() => handleWarningCancel()} text="No" />
                </DialogFooter>
              </Dialog>
            </div>
          )}
        </>
      )}

      {showSuccess && (
        <div>
          <AddLogoRequestConfirmation
            showSuccess={showSuccess}
            handleSubmitConfirmation={handleSubmitConfirmation}
            submittedOrg={submittedOrg}
            addLogoRequestResponse={addLogoRequestResponse}
          ></AddLogoRequestConfirmation>
        </div>
      )}
      {error && (
        <div>
          <AddLogoRequestError
            error={error}
            setError={() => setError(false)}
            errorMsg={errorMsg}
            errorStatusCode={errorStatusCode}
          ></AddLogoRequestError>
        </div>
      )}
    </div>
  );
};

export default AddLogo;
