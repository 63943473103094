import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React from "react";
import { dialogContentStyles } from "./Styles/AddLogoStyles";

const AddLogoRequestError = ({
  error,
  setError,
  errorMsg,
  errorStatusCode,
}: {
  error: boolean;
  setError: () => void;
  errorMsg: any;
  errorStatusCode: any;
}) => {
  let addLogoRequestFailedDialogContentProps = {
    type: DialogType.normal,
    title: errorStatusCode === 400 ? "Invalid Request" : "Request Failed.",
    subText:
      errorStatusCode === 400
        ? errorMsg.detailedMessages[0]
        : `An error occurred while adding the logo. Please try again later.`,
    // subText: `An error occurred while adding the logo. Please try again later.`,
    styles: dialogContentStyles,
  };
  let detailsArray;
  if (errorStatusCode === 400) {
    detailsArray = errorMsg.detailedMessages;
    detailsArray.shift();
  }

  return (
    <>
      <Dialog hidden={!error} dialogContentProps={addLogoRequestFailedDialogContentProps}>
        {errorStatusCode == 400 && (
          <div style={{ overflowY: "auto", maxHeight: "200px" }}>
            <ul>
              {detailsArray.map((msg: any) => (
                <li>{msg}</li>
              ))}
            </ul>
          </div>
        )}
        <DialogFooter>
          <PrimaryButton onClick={() => setError()} text="Close" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default AddLogoRequestError;
