import React, { useState, useEffect } from "react";
import { addFav } from "../../../Services/API/Favorites/addFav";
import emptyStar from "../../../assets/emptyStar.svg";
import fullStar from "../../../assets/fullStar.svg";
// import { getFavs } from "../../../Services/API/Favorites/getFavs";
import { deleteFav } from "../../../Services/API/Favorites/deleteFav";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { Photo } from "../ImageSearch.Interfaces";

const FavStar = ({ item, setError, setFavPhotos, setPhotos, handleSelect, isFavTab, selectedPhotos }: any) => {
  const [favSelected, setFavSelected] = useState(item.isFavorite);
  const [favId, setFavId] = useState(item.userFavoriteId);
  const [loading, setLoading] = useState(false);

  const handleFav = async () => {
    try {
      setLoading(true);
      if (favSelected) {
        await deleteFav(favId);
        setFavSelected(false);
        setFavPhotos((current: any) => {
          return current.filter((fav: any) => fav[0].userFavoriteId !== item.userFavoriteId);
        });
        if (isFavTab) {
          if (selectedPhotos.some((photo: any) => photo.id === item.id)) {
            handleSelect(item);
          }
        }

        setPhotos((current: any) => {
          return current.map((innerArray: any) => {
            return innerArray.map((photo: any) => {
              if (photo.imageId === item.imageId) {
                return { ...photo, isFavorite: false };
              }
              return photo;
            });
          });
        });

        //This set current image on grid is the function that we need to store the current image on the grid, as we are having corrosel to show image

        // getLogoList();
      } else {
        const res = await addFav({ brandId: item.brandId, imageId: item.imageId });
        setFavId(res.data.userFavoriteId);

        setFavSelected(true);
        setPhotos((current: any) => {
          return current.map((innerArray: any) => {
            return innerArray.map((photo: any) => {
              if (photo.imageId === item.imageId) {
                return { ...photo, isFavorite: true, userFavoriteId: res.data.userFavoriteId };
              }
              return photo;
            });
          });
        });
      }
      setLoading(false);
    } catch (error) {
      setError("Unable to add favorite");
      setLoading(false);
      setFavSelected((current: any) => current);
    }
  };

  return (
    <>
      {loading ? (
        <div className={favSelected ? "pg-cell-full-star" : "pg-cell-star"}>
          <Spinner size={SpinnerSize.xSmall} />
        </div>
      ) : (
        <div>
          <img
            className={favSelected ? "pg-cell-full-star" : "pg-cell-star"}
            src={favSelected ? fullStar : emptyStar}
            onClick={() => {
              handleFav();
            }}
            alt="star"
          />
        </div>
      )}
    </>
  );
};

export default FavStar;
